<template>
  <div>
    
    <div class="d-flex justify-content-end py-2">
      <b-button variant="primary" v-b-modal.modal-lg>
        <b-icon icon="plus-circle"></b-icon>
        Create Job Card
      </b-button>
      <b-button variant="primary" v-b-modal.modal-default class="mx-2">
        <b-icon icon="plus-circle"></b-icon>
        Push Orders
      </b-button>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Delivery Date From" label-for="h-Order-name" label-cols-md="4">
            <flat-pickr v-model="deliveryDateFrom" class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" @on-close="orderFilterChanged()" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Delivery Date To" label-for="h-Order-name" label-cols-md="4">
            <flat-pickr v-model="deliveryDateTo" class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" @on-close="orderFilterChanged()" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Products" label-for="h-Order-name" label-cols-md="2">
        <b-form-select v-model="selectedProduct" :options="optionsProduct" @change="orderFilterChanged()">
          <option value="">All</option>
        </b-form-select>
      </b-form-group>
    </div>
    <b-modal id="modal-lg" centered size="xl" title="Create Job Card" hide-footer>
      <b-row>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="info">
            <h3>Product Name</h3>
            <h1 class="text-warning">{{ productName }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="warning">
            <h3>Available Balance</h3>
            <h1 class="text-info">{{ availableBalance }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="info">
            <h3>Quantity Requested</h3>
            <h1 class="text-warning">{{ quantityRequested }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="info">
            <h3>Demand Quantity</h3>
            <h1 class="text-warning">{{ demandQuantity }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="warning">
            <h3>Production Limit</h3>
            <h1 class="text-info">{{ productionLimit }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="success">
            <h3>Production Quantity</h3>
            <h1 class="text-warning">{{ quantityToBeProduced }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="text-center" bg-variant="secondary">
            <h3>Job Cards</h3>
            <h1 class="text-warning">{{ numberOfJobCards }}</h1>
          </b-card>
        </b-col>
        <b-col cols="3">
          <h6 class="mb-2">Choose Warehouse:</h6>
          <b-form-select v-model="warehouseId" :options="optionsWarehouse" />
        </b-col>
        <b-col cols="12">
          <CreateJob :Orders="selectedOrders" :ProductionLimit="productionLimit" />
        </b-col>
        <b-col cols="12" style="text-align: right">
          <!-- submit and reset -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
            @click="addJobCard">
            <b-spinner small v-if="jobLoading" />
            Add Job Card(s)
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-default" centered title="Create Push Order" hide-footer>
      <b-container>
        <validation-observer ref="addPushForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Product">
                  <b-form-select v-model="itemIdProduct" :options="optionsProduct" />
                </b-form-group>
              </b-col>

              <!-- password -->
              <b-col cols="12">
                <b-form-group label="Quantity">
                  <validation-provider #default="{ errors }" name="Quantity" vid="Quantity">
                    <b-form-input id="Quantity" v-model="Quantity" :state="errors.length > 0 ? false : null"
                      name="Quantity" type="number" placeholder="Quantity" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Comment" label-for="login-Comment">
                  <validation-provider #default="{ errors }" name="Comment" vid="Comment">
                    <b-form-textarea id="login-Comment" v-model="Comment" :state="errors.length > 0 ? false : null"
                      name="login-Comment"
                      placeholder="Description for Push Order | Add Customer details if for customer" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Start Date" label-for="Start Date">
                  <validation-provider #default="{ errors }" name="Start Date" vid="Start Date">
                    <b-form-datepicker id="Start Date" v-model="DeliveryDate" :state="errors.length > 0 ? false : null"
                      name="Start Date" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- checkbox -->

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                  @click="AddPushOrder">
                  <b-spinner small v-if="pushLoading" />
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-container>
    </b-modal>

    <b-modal id="modal-edit-push" centered title="Edit Push Order" hide-footer>
      <b-container>
        <validation-observer ref="addPushForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Product">
                  <b-form-select v-model="pushId.itemId" :options="optionsProduct" disabled />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Order Number">
                  <b-form-input id="orderNumber" v-model="pushId.orderNumber" name="orderNumber" type="text"
                    placeholder="orderNumber" readonly />
                </b-form-group>
              </b-col>

              <!-- password -->
              <b-col cols="12">
                <b-form-group label="Quantity">
                  <b-form-input id="Quantity" v-model="pushId.quantity" name="Quantity" type="text" placeholder="Quantity"
                    readonly />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Comment" label-for="login-Comment">
                  <b-form-textarea id="login-Comment" v-model="pushId.comment" name="login-Comment" required
                    placeholder="Description for Push Order | Add Customer details if for customer" />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Start Date" label-for="Start Date">
                  <b-form-datepicker id="Start Date" v-model="pushId.deliveryDate" name="Start Date" disabled />
                </b-form-group>
              </b-col>

              <!-- checkbox -->

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                  @click="updatePushOrders">
                  <b-spinner small v-if="pushLoading" />
                  Submit
                </b-button>

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger" class="mr-1"
                  @click="deletePush">
                  Delete
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-container>
    </b-modal>

    <b-card title="Orders" class="my-2">
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPageDemand" :items="demandList"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFilteredDemand">
            <template #cell(orderId)="data">
              {{
                data.item.salesOrderId !== null
                ? data.item.salesOrderNumber
                : data.item.pushOrderNumber
              }}
            </template>
            <template #cell(orderType)="data">
              {{ data.item.salesOrderId !== null ? "Proforma" : "Push Order" }}
            </template>
            <template #cell(deliveryDate)="data">
              {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
            </template>
            <template #cell(select)="data">
              <b-form-checkbox v-model="selectedOrders" @change="selectedOrderChanged()" :value="data.item"
                class="custom-control-primary">
              </b-form-checkbox>
            </template>

            <template #cell(actions)="data">
              <b-button v-if="data.item.pushOrderId" variant="primary" v-b-modal.modal-edit-push
                @click="getPushData(data.item.pushOrderId)">
                View
              </b-button>
              <span v-else class="text-center"> - </span>

            </template>
          </b-table>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner variant="success" label="Spinning" block v-if="
              jobLoading || customerLoading || productLoading || pushLoading
            " style="width: 3rem; height: 3rem"></b-spinner>
          </div>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPageDemand" :total-rows="totalRowsDemand" :per-page="perPage" align="center"
            size="sm" class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormInput,
  BForm,
  BFormDatepicker,
  BModal,
  BDropdown,
  BDropdownItem,
  VBModal,
  BFormSelect,
  BFormGroup,
  BPagination,
  BFormTextarea,
  BContainer,
  BIcon,
  BSpinner,
  BInputGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import CreateJob from "./component/CreateJob.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import axios from "axios";

export default {
  components: {
    BTable,
    BFormGroup,
    BButton,
    flatPickr,
    BFormSelect,
    BModal,
    BPagination,
    BForm,
    BFormCheckbox,
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BFormTextarea,
    CreateJob,
    ValidationProvider,
    ValidationObserver,
    BContainer,
    BIcon,
    BSpinner,
    BInputGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      ContactPerson: "",
      itemIdProduct: "",
      Quantity: null,
      Comment: "",
      DeliveryDate: null,
      selectedProduct: null,
      date: null,
      dateDefault: null,
      timePicker: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      leadClientId: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRowsDemand: 1,
      totalRowsPush: 1,
      currentPagePush: 1,
      currentPageDemand: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      optionsProduct: [],
      selectedOrders: [],
      quantityRequested: null,
      availableBalance: null,
      demandQuantity: null,
      productionLimit: null,
      quantityToBeProduced: null,
      numberOfJobCards: null,
      productName: null,
      dayjs,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "orderId",
          label: "Order ID",
        },
        {
          key: "orderType",
          label: "Order Type",
        },
        {
          key: "productName",
          label: "Product Name",
          sortable: true,
        },
        {
          key: "deliveryDate",
          label: "Start Date",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: true,
        },

        {
          key: "select",
          label: "Select Order",
        },

        {
          key: "actions",
          label: "Actions",
        },
      ],

      optionsCustomer: [],

      demandList: [],
      optionsWarehouse: [],
      warehouseId: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("jobcardModule", {
      jobDemand: "jobDemand",
      jobLoading: "loading",
    }),
    ...mapGetters("customerModule", {
      CustomerList: "customers",
      customerLoading: "loading",
    }),
    ...mapGetters("productModule", {
      products: "product",
      productLoading: "loading",
    }),
    ...mapGetters("pushModule", {
      pushId: "pushId",
      pushLoading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),
  },
  methods: {
    // success
    deletePush() {
  this.$swal({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    customClass: {
      confirmButton: "btn btn-primary",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.value) {
      let accessToken = localStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      let payload = {
        id: this.pushId.id,
      };
      const url = process.env.VUE_APP_API_URLv1
      axios
        .delete(url + "pushorders/pushorder", {
          data: payload,
          headers: config.headers,
        })
        .then(() => {
          this.successDelete();
       
        })
        .catch((err) => {
          this.errorAdd(err.response.data);
        });
    }
  });
},

successDelete() {
  this.$swal({
    title: "Deleted!",
    text: "Your push order has been deleted.",
    icon: "success",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  }).then(() => {
    window.location.reload();
  });
},

    successUpdate() {
      this.$swal({
        title: "Push Order DELETED!",
        text: "Your Push Order has been deleted !",
        icon: "warnign",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    updatePushOrders(){
      let payload = {
        id: this.pushId.id,
        itemId: this.pushId.itemId,
        quantity: this.pushId.quantity,
        comment: this.pushId.comment,
        contactPersonId: this.pushId.contactPersonId,
        deliveryDate: this.pushId.deliveryDate,
        isActive: true,
      }

      this.updatePushAction(payload).then(() => {
        this.successUpdate()
        this.$bvModal.hide("modal-edit-push");
      }).catch((err) => {
        this.errorAdd(err.response.data)
        this.$bvModal.hide("modal-edit-push");
      })
    },


    getPushData(id) {
      this.getPushByIdAction(id)
    },
    successUpdate() {
      this.$swal({
        title: "Push Order Updated!",
        text: "You have successfully updated your Push Order !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    successAdd() {
      this.$swal({
        title: "Push Order Added!",
        text: "You have successfully added a Push Order !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    // error
    errorAdd(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (
            Object.prototype.hasOwnProperty.call(errors, prop) &&
            prop !== "request"
          ) {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    showMessage(icon, title, msg, reRoute) {
      this.$swal({
        title: title,
        text: msg,
        icon: icon,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value && reRoute) {
          this.$router.push({ name: reRoute });
        }
      });
    },
    addJobCard() {
      let orders = this.selectedOrders;
      if (orders.length > 0 && this.quantityToBeProduced > 0) {
        let description = "O / ";
        let index = 1;
        orders.forEach((obj) => {
          description += obj.salesOrderNumber ?? obj.pushOrderNumber;
          if (index < orders.length) {
            description += "|";
          }
          index += 1;
        });
        let payload = {
          name: "Job Card",
          description: description,
          quantity: parseInt(this.quantityToBeProduced),
          productionLimit: this.productionLimit,
          itemID: orders[0].itemID,
          salesOrderInfo: orders
            .filter((obj) => {
              return obj.salesOrderId !== null;
            })
            .map((obj) => {
              return { saleOrderId: obj.salesOrderId, quantity: obj.quantity, deliveryDate: obj.deliveryDate };
            }),
          pushOrderInfo: orders
            .filter((obj) => {
              return obj.pushOrderId !== null && obj.pushOrderId !== "APO";
            })
            .map((obj) => {
              return { pushOrderId: obj.pushOrderId, quantity: obj.quantity, deliveryDate: obj.deliveryDate };
            }),
          deliveryDate: orders[0].deliveryDate,
          startDate: new dayjs().add(1, "day"),
          endTime: orders[0].deliveryDate,
          plannedCost: 0,
          actualCost: 0,
          totalCost: 0,
          additionalCost: 0,
          isActive: true,
          warehouseId: this.warehouseId
        };
        console.log(payload);
        this.addJobcardAction(payload)
          .then(() => {
            this.$bvModal.hide("modal-lg");
            this.showMessage(
              "success",
              "Job Card Added!",
              "You have successfully added a Job Card!",
              "jobcard-list"
            );
          })
          .catch((ex) => {
            this.errorAdd(ex.response.data);
            this.$bvModal.hide("modal-default");
          });
      }
    },
    AddPushOrder() {
      let payload = {
        isActive: true,
        itemId: this.itemIdProduct,
        quantity: this.Quantity,
        comment: this.Comment,
        contactPersonId: null,
        startDate: this.DeliveryDate,
      };
      this.addPushAction(payload)
        .then(() => {
          this.successAdd();
          this.$bvModal.hide("modal-default");
          this.itemIdProduct = null;
          this.Quantity = null;
          this.Comment = null;
          this.DeliveryDate = null;

          this.getJobcardDemandAction()
            .then(() => {
              this.demandList = this.jobDemand;
              this.totalRowsDemand = this.jobDemand.length;
            })
            .catch((response) => {
              console.log(response);
            });
        })
        .catch(() => {
          this.errorAdd();
          this.$bvModal.hide("modal-default");
        });
    },
    selectedOrderChanged() {
      // Remove temp TBD orders
      this.selectedOrders.forEach((obj) => {
        if (obj.pushOrderId === "APO") {
          this.selectedOrders.splice(this.selectedOrders.indexOf(obj), 1);
        }
      });

      let orders = this.selectedOrders;

      this.quantityRequested = 0;
      this.availableBalance = 0;
      this.demandQuantity = 0;
      this.productionLimit = 0;
      this.quantityToBeProduced = 0;
      this.numberOfJobCards = 0;
      if (orders.length > 0) {
        let firstOrder = orders[0];
        orders.forEach((obj) => {
          if (obj.itemID !== firstOrder.itemID) {
            let orderIndex = orders.indexOf(obj);
            if (orderIndex !== -1) {
              orders.splice(orderIndex, 1);
              this.showMessage(
                "info",
                "",
                "Cannot select different products for the same job card"
              );
            }
          }
        });
        let totalQuantity = orders.reduce(function (a, b) {
          return a + b["quantity"] ?? 0;
        }, 0);
        let totalDemandQuantity = orders.reduce(function (a, b) {
          return a + b["demandQty"] ?? 0;
        }, 0);
        this.quantityRequested = totalQuantity;
        let itemAvailableBalance = firstOrder.originalBalance ?? 0;
        this.availableBalance = itemAvailableBalance;
        if (itemAvailableBalance < totalQuantity)
          totalDemandQuantity = totalQuantity - itemAvailableBalance;
        else totalDemandQuantity = 0;
        this.demandQuantity = totalDemandQuantity;
        let product = this.products.filter((obj) => {
          return obj.id === firstOrder.itemID;
        });
        this.productName = product[0].name;
        this.productionLimit = product[0].productionLimit ?? 0;
        if (this.productionLimit === 0) this.productionLimit = totalQuantity;
        if (totalQuantity > this.productionLimit)
          this.numberOfJobCards = Math.ceil(
            totalQuantity / this.productionLimit
          );
        else this.numberOfJobCards = 1;
        this.quantityToBeProduced =
          this.productionLimit * this.numberOfJobCards;
      }
    },
    ...mapActions("jobcardModule", ["getJobcardDemandAction"]),
    ...mapActions("customerModule", ["getCustomersListAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("jobcardModule", ["addJobcardAction"]),
    ...mapActions("pushModule", ["getPushListAction", "addPushAction", "updatePushAction", "getPushByIdAction", "removePushAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),
    onFilteredDemand(filteredItems) {
      this.totalRowsDemand = filteredItems.length;
      this.currentPageDemand = 1;
    },
    success() {
      this.$swal({
        title: "New Job Card Created!",
        text: "Please check the Job Card Lists!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    orderFilterChanged() {
      this.demandList = this.jobDemand;
      if (this.selectedProduct) {
        this.demandList = this.demandList.filter((obj) => {
          return obj.itemID === this.selectedProduct;
        });
      }
      if (this.deliveryDateFrom) {
        this.demandList = this.demandList.filter((obj) => {
          return obj.deliveryDate >= this.deliveryDateFrom;
        });
      }
      if (this.deliveryDateTo) {
        this.demandList = this.demandList.filter((obj) => {
          return obj.deliveryDate <= this.deliveryDateTo;
        });
      }
      this.totalRowsDemand = this.demandList.length;
      this.currentPageDemand = 1;
    },
  },

  async mounted() {
    await this.getCustomersListAction().then(() => {
      this.CustomerList.map((v, i) =>
        this.optionsCustomer.push({
          value: v.id,
          text: `${v.otherName} ${v.surname}`,
        })
      );
    });
    await this.getProductListAction().then(() => {
      this.products.map((v, i) => {
        if ((v.type === 1 || v.type === 2) && v.inHouseManufactured === true) {
          this.optionsProduct.push({ value: v.id, text: `${v.name}` });
        }
      });
    });
    await this.getJobcardDemandAction()
      .then(() => {
        this.demandList = this.jobDemand;
        this.totalRowsDemand = this.jobDemand.length;
      })
      .catch((response) => {
        console.log(response);
      });
    await this.getWarehouseListAction().then(() => {
      this.warehouses.map((v, i) =>
        this.optionsWarehouse.push({
          value: v.id,
          text: `${v.name}`,
        })
      );
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
