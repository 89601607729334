<template>
  <div>
    <b-table small :fields="fields" :items="Orders" responsive="sm">
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(orderId)="data">
        {{
          data.item.salesOrderId !== null
            ? data.item.salesOrderNumber
            : data.item.pushOrderNumber
        }}
      </template>
      <template #cell(orderType)="data">
        <b-badge
          pill
          variant="info"
          class="p-1"
          v-if="data.item.salesOrderId !== null"
        >
          Pulled Order
        </b-badge>
        <b-badge pill variant="primary" class="p-1" v-else>
          Pushed Order
        </b-badge>
      </template>
    </b-table>
  </div>
</template>
  
<script>
import {
  BTable,
  BProgress,
  BBadge,
  BButton,
  BFormGroup,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BProgress,
    BFormGroup,
    BFormDatepicker,
    BBadge,
    BButton,
  },
  data() {
    return {
      fields: [
        "index",
        {
          key: "orderId",
          label: "Order ID",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "orderType",
          label: "Order Type",
        },
      ],
    };
  },
  props: {
    Orders: {
      type: Array,
      required: true,
    },
    ProductionLimit: {
      type: Number,
      required: true,
    },
  },
  methods: {
    checkProductionLimit() {
      let orders = this.Orders;

      if (orders.length > 0) {
        let totalQuantity = orders.reduce(function (a, b) {
          return a + b["quantity"] ?? 0;
        }, 0);        
        let numberOfJobCards = Math.ceil(totalQuantity / this.ProductionLimit);
        let quantityToBeProduced = this.ProductionLimit * numberOfJobCards;
        if (quantityToBeProduced > totalQuantity) {
          let pushOrderQty = quantityToBeProduced - totalQuantity;
          this.Orders.push({
            salesOrderId: null,
            pushOrderId: "APO",
            pushOrderNumber: "APO",
            quantity: pushOrderQty,
          });
        }
      }
    },
  },
  mounted() {
    this.checkProductionLimit();
  },
};
</script>